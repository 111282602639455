import React from 'react'
import styled from 'styled-components'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { SPACING } from '../constants'
// Components
import { P, B, H1, H2, H3, H4, A, UL } from './Typography'

const Image = styled.img`
  margin-top: ${SPACING.XL};

  + h2 {
    margin-top: ${SPACING.S};
  }
`
// eslint-disable-next-line react/prop-types
const Bold = ({ children }) => <B>{children}</B>
const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: (text) => <P as="em">{text}</P>,
    [MARKS.UNDERLINE]: (text) => <P as="u">{text}</P>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <P>{children}</P>,
    [BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
    [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
    [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
    [BLOCKS.HEADING_4]: (node, children) => <H4>{children}</H4>,
    [BLOCKS.UL_LIST]: (node, children) => <UL>{children}</UL>,
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => (
      <Image
        src={node.data.target.fields.file['en-GB'].url}
        alt={node.data.target.fields.title}
        caption={node.data.target.fields.description}
      />
    ),
    // [INLINES.ENTRY_HYPERLINK]: (node, children) => {
    //   return <LinkResolver input={node.data.target}>{children}</LinkResolver>
    // },
    [INLINES.HYPERLINK]: (node, children) => (
      <A href={node.data.uri} rel="noopener noreferrer">
        {children}
      </A>
    ),
    // [BLOCKS.EMBEDDED_ENTRY]: (node) => {
    //   const { fields } = node.data.target
    //   const contentType = node.data.target.sys.contentType.sys.id
    //   switch (contentType) {
    //     case 'colorBlock':
    //       return <ColorBlock input={fields} />
    //     default:
    //       return null
    //   }
    // },
  },
}
const RichText = (input) => (
  <>{documentToReactComponents(input.json, options)}</>
)

export default RichText
